.PageNotFound {
	position: relative;
	width: 100%;
	height: 100vh;
	background: #00001d !important;
}

.PageNotFound .sec01 {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 55em;
}

.PageNotFound .sec01 .content h2:first-of-type {
	font-size: 3.3vw;
}

.PageNotFound .sec01 .content h2 {
	font-size: 2.2vw;
	font-family: Helvetica;
	font-weight: bold;
	color: #ffffff;
	line-height: 2.3vw;
	margin-bottom: 0.93vw;
}

.PageNotFound .sec01 .content h2:last-of-type {
	margin-bottom: 0.9em;
}

.PageNotFound .sec01 .content p {
	font-size: 1.2vw;
	font-family: Helvetica;
	font-weight: 400;
	color: #ffffff;
	line-height: 0.9vw;
	margin-bottom: 0.93vw;
}

/* sec02 */
.PageNotFound .sec02 {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background: url('../../assets/images/sec6Bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 5.6vw;
}

.PageNotFound .sec02 .content .left a,
.PageNotFound .sec02 .content .right a {
	font-size: 1.04vw;
	font-family: Helvetica;
	font-weight: 300;
	color: #ffffff;
	line-height: 2.08vw;
	text-decoration: none;
	display: block;
	text-align: left;
}

.PageNotFound .sec02 .footerBottom .footerLine {
	display: block;
	width: 38.12vw;
	height: 0.15vw;
	background: #5049d4;
	opacity: 0.22;
	margin: 1.34vw auto 0.2vw;
}

.PageNotFound .sec02 .footerBottom .copy {
	width: 100%;
	margin: 0 auto;
	margin-bottom: 1.04vw;
	text-align: center;
}

.PageNotFound .sec02 .footerBottom .copy span {
	font-size: 0.72vw;
	font-family: Arial;
	font-weight: 400;
	color: #ffffff;
	margin: 0 0.98vw 1.04vw;
	display: inline-block;
	opacity: 0.5;
}

.PageNotFound .sec02 .ant-col-6 {
	max-width: 28%;
	flex: 0 0 28%;
}

.PageNotFound .sec02 .ant-col-6:nth-child(2) {
	margin-right: 15%;
}

.PageNotFound .sec02 .ant-col-6:first-child,
.PageNotFound .sec02 .ant-col-6:last-child {
	max-width: 20%;
	flex: 0 0 20%;
}

/* pc media */
@media (min-width: 1000px) and (max-width: 1400px) {
    .PageNotFound .sec01 {
        width: 45em;
    }
}

@media (min-width: 768px) and (max-width: 999px) {
    .PageNotFound .sec01 {
        width: 40em;
    }
}

/* mobile */
@media (min-width: 320px) and (max-width: 767px) {
	.PageNotFound {
		position: relative;
        width: 100%;
        height: 100vh;
	}

	.PageNotFound .sec01 {
		position: absolute;
        margin-top: 4em;
		top: 30%;
		height: 28em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.PageNotFound .sec01 .error-image {
		width: 20em;
	}


	.PageNotFound .sec01 .content h2:first-of-type {
		font-size: 5.3vw;
	}

	.PageNotFound .sec01 .content h2 {
		font-size: 4.2vw;
		font-family: Helvetica;
		font-weight: bold;
		color: #ffffff;
		line-height: 3.5vw;
		margin-bottom: 1.93vw;
	}

	.PageNotFound .sec01 .content h2:last-of-type {
		margin-bottom: 1.3em;
	}

	.PageNotFound .sec01 .content p {
		font-size: 3.2vw;
		font-family: Helvetica;
		font-weight: 400;
		color: #ffffff;
		line-height: 2vw;
		margin-bottom: 2vw;
	}

	/* sec02 */
	.PageNotFound .sec02 {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		background-size: 200% 100%;
		background-position: center center;
		padding-top: 34vw;
		padding-bottom: 5.73vw;
		margin-top: -9vw;
	}

	.PageNotFound .sec02 .ant-col-6 {
		max-width: 40% !important;
		flex: 0 0 40% !important;
	}

	.PageNotFound .sec02 .ant-col-6:nth-child(2) {
		margin-right: 0;
	}

	.PageNotFound .sec02 .ant-col-6:first-child,
	.PageNotFound .sec02 .ant-col-6:last-child {
		max-width: 10% !important;
		flex: 0 0 10% !important;
	}

	.PageNotFound .sec02 .content .left a,
	.PageNotFound .sec02 .content .right a {
		font-size: 3.2vw;
		font-family: Helvetica;
		font-weight: 300;
		color: #ffffff;
		line-height: 8vw;
		text-decoration: none;
		display: block;
		text-align: left;
	}

	.PageNotFound .sec02 .footerBottom .footerLine {
		display: block;
		width: 81.33vw;
		height: 0.13vw;
		background: #4f4dd4;
		opacity: 0.22;
		margin: 8.66vw auto 4.93vw;
	}

	.PageNotFound .sec02 .footerBottom .copy {
		width: 100%;
		padding: 0 9.46vw;
		text-align: left;
	}

	.PageNotFound .sec02 .footerBottom .copy span {
		font-size: 3.2vw;
		font-family: Arial;
		font-weight: 400;
		line-height: 6vw;
		color: #ffffff;
		margin: 0;
		display: inline-block;
		width: 100%;
		opacity: 0.5;
	}
}
