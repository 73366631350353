.HomeHeader {
  width: 100%;
  margin: 0.93vw auto;
}

.HomeHeader img {
  width: 21vw;
}

.HomeHeader .rightNav {
  float: right;
  margin-top: 0.46vw;
  margin-bottom: 0;
  line-height: 2.18vw;
  margin-right: 1vw;
}

.HomeHeader .rightNav img {
  width: 20px;
  margin-right: -23px;
}

.HomeHeader .rightNav .ant-select .ant-select-selector, .HomeHeader .rightNav .ant-select-focused, .HomeHeader .rightNav .ant-select {
  background: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.HomeHeader .rightNav .ant-select-selection-item {
  visibility: hidden;
}

.HomeHeader .rightNav .ant-select-arrow span {
  font-size: 12px;
  vertical-align: middle;
  margin-top: 0.2vw;
}

.HomeHeader .LaunchAppBtn {
  min-width: 6.14vw;
  min-height: 2.29vw;
  border-radius: 1.09vw;
  text-align: center;
  padding: 0.05vw;
  background: linear-gradient(-43deg, #DC30F6, #2D6AE8);
  background-clip: content-box,padding-box;
  font-size: 0.72vw;
  line-height: 2.18vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  text-decoration: none;
  float: right;
  margin-top: 0.46vw;
  vertical-align: middle;
}

.HomeHeader .LaunchAppBtn:hover {
  background: linear-gradient(-43deg, #c32bda, #2962d3);
  color: #dadada;
}

.HomeHeader .rightNav span {
  font-size: 0.72vw;
  vertical-align: middle;
  color: #fff;
  cursor: pointer;
}

.HomeHeader .rightNav span img {
  width: 1.04vw;
  margin-right: 0.52vw;
  vertical-align: middle;
  margin-top: -2px;
}

.rightNav2 {
  float: right;
  margin-bottom: 0;
  line-height: 2.18vw;
}

.rightNav2 img {
  width: 20px;
  margin-right: -23px;
}

.rightNav2 .ant-select .ant-select-selector, .rightNav2 .ant-select-focused, .rightNav2 .ant-select {
  background: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.rightNav2 .ant-select-selection-item {
  visibility: hidden;
}

.rightNav2 .ant-select-arrow span {
  font-size: 12px;
  vertical-align: middle;
  margin-top: 0.2vw;
}

.rightNav2 span {
  font-size: 0.72vw;
  vertical-align: middle;
  color: #fff;
  cursor: pointer;
}

.rightNav2 span img {
  width: 1.04vw;
  margin-right: 0;
  vertical-align: middle;
  margin-top: -2px;
}

@media(min-width: 1000px) and (max-width: 1300px) {
  .HomeHeader .LaunchAppBtn {
    font-size: 1.2vw;
    min-width: 12vw;
    min-height: 4vw;
    line-height: 3.5vw;
    border-radius: 3vw;
    transform: scale(0.6);
    transform-origin: left top;
  }
  .HomeHeader .rightNav {
    font-size: 1.2vw;
    line-height: 3.5vw;
    transform: scale(0.6);
    transform-origin: right top;
  }
}

@media(min-width: 768px) and (max-width: 999px) {
  .HomeHeader .LaunchAppBtn {
    font-size: 1.1vw;
    width: 12vw;
    height: 4vw;
    line-height: 3.8vw;
    transform: scale(0.6);
    border-radius: 3vw;
    transform-origin: left top;
  }
  .HomeHeader .rightNav {
    font-size: 1.1vw;
    line-height: 3.8vw;
    transform: scale(0.6);
    transform-origin: right top;
  }
  .rightNav2 span img {
    display: none !important;
  }
}

/* mobile */
@media(min-width: 320px) and (max-width: 767px) {
  .HomeHeader {
    text-align: center;
    padding-top: 3vw;
  }
  .HomeHeader img {
    width: 70vw;
  }
  .HomeHeader .LaunchAppBtn {
    display: none;
  }
  .HomeHeader .ant-col-offset-2 {
    margin-left: 0 !important;
  }
  .HomeHeader .ant-col-20 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .HomeHeader .rightNav {
    position: absolute;
    right: 0vw;
    top: 10vw;
  }
  .HomeHeader .rightNav span img {
    width: 3vw;
    margin-right: 0.5vw;
    vertical-align: middle;
    margin-top: -1vw;
  }
  .HomeHeader .rightNav span {
    font-size: 4vw;
  }
  .rightNav2 span {
    margin-left: 6px;
  }
  .rightNav2 img {
    margin-right: -40px;
    margin-top: -4px;
  }
  .rightNav2 {
    position: unset;
    margin-left: 10px;
  }
  .rightNav2 span img {
    width: 3vw;
    margin-right: 0.5vw;
    vertical-align: middle;
    margin-top: -1vw;
  }
  .rightNav2 span {
    font-size: 16px;
  }
  .rightNav2 span img {
    display: none !important;
  }
}