/* sec01 */
.Home{
  position: relative;
  width: 100%;
  background: #00001d !important;
}

.Home .sec01 {
  background: url('../../assets/images/banner1Left.png') left top no-repeat, url('../../assets/images/banner1Right.png') right top no-repeat,#00001d;
  position: relative;
  background-size: 100%;
}

.Home .sec01 .content h1 {
  padding-top: 11.56vw;
  font-size: 3.75vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #D82FF6;
  line-height: 4.27vw;
  margin-bottom: 0.31vw;
}

.Home .sec01 .content h2 {
  font-size: 3.75vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 3.75vw;
  margin-bottom: 0.93vw;
}

.Home .sec01 .content span.colorSpan {
  width: 1.56vw;
  height: 0.52vw;
  background: #D82FF6;
  display: block;
}

.Home .sec01 .content a.Btn {
  margin-top: 3.38vw;
  width: 13vw;
  height: 2.5vw;
  background: linear-gradient(-43deg, #BC29BB, #315ECF);
  border-radius: 0.31vw;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 0.83vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 2.39vw;
}

.Home .sec01 .content a.Btn:hover {
  background: linear-gradient(-43deg, #c32bda, #2962d3);
  color: #dadada;
}

.Home .sec01 .content .pc {
  display: block;
}

.Home .sec01 .content .mobile {
  display: none;
}

.Home .sec01 .content p.textP span {
  font-size: 1.04vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 0.93vw;
  margin-top: 2.91vw;
  display: inline-block;
  margin-bottom: 0.93vw;
}

.Home .sec01 .content p.textP span:last-child {
  float: right;
  margin-right: 10vw;
}

.Home .sec01 .content .bottomData .left {
  float: left;
  /* margin-right: 22.29vw; */
  vertical-align: top;
}

.Home .sec01 .content .bottomData .left img {
  margin-right: 1.77vw;
  width: 3.22vw;
}

.Home .sec01 .content .bottomData .right {
  vertical-align: top;
  float: right;
}

.Home .sec01 .content .bottomData .right .circular {
  width: 8.33vw;
  height: 8.33vw;
  padding: 3.02vw 0.1vw 2.29vw;
  border: 0.1vw solid #3A60FF;
  box-shadow: 0 0px 0.52vw 0px #3A60FF;
  border-radius: 50%;
  text-align: center;
  margin-right: 2.33vw;
  display: inline-block;
  vertical-align: top;
  background: #202230;
  margin-bottom: 0;
}

.Home .sec01 .content .bottomData .right .circular h4 {
  font-size: 1.92vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #3A60FF;
  line-height: 1.04vw;
  margin-bottom: 0.31vw;
}

.Home .sec01 .content .bottomData .right .circular span {
  font-size: 0.72vw;
  font-family: Helvetica;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 1.35vw;  
}

.Home .sec01 .content .bottomData .right .circular:nth-child(2) {
  border: 0.1vw solid #8B52F6;
  box-shadow: 0 0 0.52vw 0 #8B52F6;
}

.Home .sec01 .content .bottomData .right .circular:nth-child(2) h4 {
  color: #8B52F6;
}

.Home .sec01 .content .bottomData .right .circular:last-child {
  /* margin-right: 0; */
  border: 0.1vw solid #D82FF6;
  box-shadow: 0 0 0.52vw 0 #D82FF6;
}

.Home .sec01 .content .bottomData .right .circular:last-child h4 {
  color: #D82FF6;
}

/* sec02 */
.Home .sec02{
  width: 100%;
  position: relative;
  background: url('../../assets/images/sec2Bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 2.5vw;
}

.Home .sec02 .bgDiv {
  background: url('../../assets/images/sec2Left.png'), url('../../assets/images/sec2Right.png');
  background-repeat: no-repeat;
  background-size: 40%, 5%;
  background-position: 0% 140%, 94% 100%;
}

.Home .sec02 .content .title {
  position: relative;
  padding-top: 7.6vw;
  font-size: 2.58vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 3.12vw;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.Home .sec02 .content .titleLine {
  width: 2.6vw;
  height: 0.15vw;
  background: #D82FF6;
  display: block;
  margin: 1.3vw auto 2.6vw;
  text-align: center;
  margin-bottom: 2.55vw;
}

.Home .sec02 .content .textDiv p {
  font-size: 1.34vw;
  font-family: Helvetica;
  font-weight: 525;
  color: #FFFFFF;
  line-height: 1.71vw;
  margin-bottom: 2.7vw;
}

/* sec03 */
.Home .sec03 {
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background: url('../../assets/images/sec3Left.png');
  background-repeat: no-repeat;
  background-size: 4vw;
  background-position: 20% 100%;
  padding-bottom: 5.2vw;
}

.Home .sec03 .content .title {
  position: relative;
  padding-top: 7.6vw;
  font-size: 2.58vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 3.12vw;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.Home .sec03 .content .titleLine {
  width: 2.6vw;
  height: 0.15vw;
  background: #D82FF6;
  display: block;
  margin: 1.3vw auto 2.6vw;
  text-align: center;
  margin-bottom: 1.14vw;
}

.Home .sec03 .content .textDiv p.pc {
  font-size: 1.56vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 2.34vw;
  text-align: center;
  margin-bottom: 3.12vw;
  display: block;
}

.Home .sec03 .content .textDiv p.mobile {
  display: none;
}

.Home .sec03 .content .cardDiv .card div {
  background: #202230;
  border-radius: 0.31vw;
  padding: 1.66vw 1vw 2.29vw;
  text-align: center;
  height: 21vw;
}

.Home .sec03 .content .cardDiv .card div img {
  width: 4.69vw;
  height: 4.69vw;
  margin-bottom: 1.56vw;
}

.Home .sec03 .content .cardDiv .card div .bigFont {
  font-size: 1.34vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 2.08vw;
  margin-bottom: 0.67vw;
}

.Home .sec03 .content .cardDiv .card div .subFont {
  font-size: 0.93vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.25vw;
  margin-bottom: 1.51vw;
}

.Home .sec03 .content .cardDiv .card div .smallFont {
  font-size: 1vw;
  font-family: Helvetica;
  font-weight: 525;
  color: #FFFFFF;
  line-height: 1.09vw;
}

/* sec4 */
.Home .sec04{
  width: 100%;
  position: relative;
  background: url('../../assets/images/sec4Bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 5.52vw;
}

.Home .sec04 .content h2.title {
  position: relative;
  padding-top: 9.53vw;
  font-size: 2.58vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 3.12vw;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.Home .sec04 .content .titleLine {
  width: 2.6vw;
  height: 0.15vw;
  background: #D82FF6;
  display: block;
  margin: 1.3vw auto 2.6vw;
  text-align: center;
  margin-bottom: 8.33vw;
}

.Home .sec04 .content .leftCard1 {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.Home .sec04 .content .leftCard1 img {
  width: 14.63vw;
}

.Home .sec04 .content .rightCard1 div h2 {
  font-size: 1.87vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 2.08vw;
}

.Home .sec04 .content .rightCard1 div p {
  font-size: 1.14vw;
  font-family: Helvetica;
  font-weight: 525;
  color: #FFFFFF;
  line-height: 1.29vw;  
  margin-bottom: 1.5vw;
}

.Home .sec04 .content .rightCard1 div p a {
  font-family: Helvetica;
  font-weight: 525;
  color: #d878ea;
}

.Home .sec04 .content .rightCard1 div p a:hover {
  font-family: Helvetica;
  font-weight: 525;
  color: #bf6bce;
}

.Home .sec04 .content .rightCard1 div h2 img {
  width: 1.97vw;
  height: 1.97vw;
  margin-right: 1.56vw;
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.2vw;
}

.Home .sec04 .content .rightCard1 div p.title {
  font-size: 2vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 2.55vw;
}

.Home .sec04 .content .rightCard2 {
  margin-top: 10.88vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home .sec04 .content .rightCard2 img {
  width: 15.93vw;
}

.Home .sec04 .content .leftCard2 div h2 {
  margin-top: 10.31vw;
  font-size: 1.87vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 2.08vw;
}

.Home .sec04 .content .leftCard2 div p {
  font-size: 1.14vw;
  font-family: Helvetica;
  font-weight: 525;
  color: #FFFFFF;
  line-height: 1.29vw;  
  margin-bottom: 1.5vw;
}

.Home .sec04 .content .leftCard2 div p a{
  font-family: Helvetica;
  font-weight: 525;
  color: #d878ea;
}

.Home .sec04 .content .leftCard2 div p a:hover{
  font-family: Helvetica;
  font-weight: 525;
  color: #bf6bce;
}

.Home .sec04 .content .leftCard2 div h2 img {
  width: 1.97vw;
  height: 1.97vw;
  margin-right: 1.56vw;
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.2vw;
}

.Home .sec04 .content .leftCard2 div p.title {
  font-size: 1.87vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 2.55vw;
  line-height: 2.5vw;
}

.Home .sec04 .content .leftCard3 {
  margin-top: 11.45vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home .sec04 .content .leftCard3 img {
  width: 17.91vw;
}

.Home .sec04 .content .rightCard3 div h2 {
  margin-top: 11.45vw;
  font-size: 1.87vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 1.87vw;
}

.Home .sec04 .content .rightCard3 div p {
  font-size: 1.14vw;
  font-family: Helvetica;
  font-weight: 525;
  color: #FFFFFF;
  line-height: 1.29vw;  
  margin-bottom: 1.5vw;
}

.Home .sec04 .content .rightCard3 div h2 img {
  width: 1.97vw;
  height: 1.97vw;
  margin-right: 1.56vw;
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.2vw;
}

.Home .sec04 .content .rightCard3 div p.title {
  line-height: 2.5vw;
  font-size: 1.87vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 2.7vw;
}

.Home .sec04 .content div p a {
  color: #fff;
}

/* sec05 */
.Home .sec05.pc {
  display: block;
}

.Home .sec05.mobile {
  display: none;
}

.Home .sec05 .content .rightCard {
  margin-top: 5.88vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home .sec05 .content .rightCard img {
  width: 20.72vw;
}

.Home .sec05 .content .leftCard div h2 {
  margin-top: 6.25vw;
  font-size: 2.18vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 1.4vw;
  line-height: 1.8vw;
}

.Home .sec05 .content .leftCard div p {
  font-size: 1.14vw;
  font-family: Helvetica;
  font-weight: 525;
  color: #FFFFFF;
  line-height: 1.71vw;  
  margin-bottom: 1.5vw;
}

.Home .sec05 .content .titleLine {
  width: 2.6vw;
  height: 0.15vw;
  background: #D82FF6;
  display: block;
  margin: 1.3vw 0 2.6vw;
  text-align: left;
  margin-bottom: 1.51vw;
}

.Home .sec05 .content div p a {
  color: #fff;
}

/* sec06 */
.Home .sec06{
  width: 100%;
  position: relative;
  background: url('../../assets/images/sec6Bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 9.84vw;
}

.Home .sec06 .content .left a, .Home .sec06 .content .right a {
  font-size: 1.04vw;
  font-family: Helvetica;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 2.08vw;
  text-decoration: none;
  display: block;
  text-align: left;
}

.Home .sec06 .footerBottom .footerLine {
  display: block;
  width: 38.12vw;
  height: 0.05vw;
  background: #5049D4;
  opacity: 0.22;
  margin: 2.34vw auto 1.04vw;
}

.Home .sec06 .footerBottom .copy {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1.04vw;
  text-align: center;
}

.Home .sec06 .footerBottom .copy span {
  font-size: 0.72vw;
  font-family: Arial;
  font-weight: 400;
  color: #FFFFFF;
  margin: 0 0.98vw 1.04vw;
  display: inline-block;
  opacity: 0.5;
}

.Home .sec06 .ant-col-6 {
  max-width: 28%;
  flex: 0 0 28%
}

.Home .sec06 .ant-col-6:nth-child(2) {
  margin-right: 15%;
}

.Home .sec06 .ant-col-6:first-child, .Home .sec06 .ant-col-6:last-child {
  max-width: 20%;
  flex: 0 0 20%
}

/* pc media */
@media(min-width: 1000px) and (max-width: 1400px) { 
  .Home .sec01 .content .bottomData .right .circular span {
    display: inline-block;
    line-height: 1.35vw;
    font-size: 0.62vw;
    transform: scale(0.8);
  }
}

@media(min-width: 1000px) and (max-width: 1300px) {
  .Home .sec01 .content a.Btn {
    font-size: 0.83vw;
    width: 15vw;
    height: 3vw;
    line-height: 3vw;
  }
  .Home .sec01 .content .bottomData .right .circular span {
    display: inline-block;
    line-height: 1.35vw;
    font-size: 0.92vw;
    transform: scale(0.8);
  }
}

@media(min-width: 1000px) and (max-width: 1200px) {
  .Home .sec03 .content .cardDiv .card div {
    padding: 1.66vw 0vw 2.29vw;
    height: 20vw;
  }
  .Home .sec03 .content .cardDiv .card div .bigFont {
    font-size: 1.7vw;
    transform: scale(0.8);
    margin-bottom: 0;
    transform-origin: center top;
  }
  .Home .sec03 .content .cardDiv .card div .subFont {
    font-size: 1.4vw;
    transform: scale(0.8);
    margin-bottom: 0;
    line-height: 1.5vw;
    transform-origin: center top;
  }
  .Home .sec03 .content .cardDiv .card div .smallFont {
    font-size: 1.2vw;
    transform: scale(0.8);
    line-height: 1.2vw;
    transform-origin: center top;
  }
}

@media(min-width: 768px) and (max-width: 999px) {
  .Home .sec01 .content a.Btn {
    font-size: 1.5vw;
    width: 20vw;
    height: 3vw;
    line-height: 3vw;
    transform: scale(0.8);
    transform-origin: left top;
  }
  .Home .sec01 .content .bottomData .right {
    vertical-align: top;
    float: right;
    transform: scale(0.8);
    transform-origin: right top;
    margin-top: -0.8vw;
  }
  .Home .sec01 .content p.textP span:last-child {
    margin-right: 7vw;
  }
  .Home .sec01 .content .bottomData .right .circular {
    width: 12.33vw;
    height: 12.33vw;
    padding: 4.52vw 0.1vw 2.29vw;
    border: 0.1vw solid #3A60FF;
    box-shadow: 0 0px 0.52vw 0px #3a60ff;
    border-radius: 50%;
    text-align: center;
    margin-right: 1.55vw;
    display: inline-block;
    vertical-align: top;
    background: #202230;
    margin-bottom: 0;
    transform: scale(0.8);
  }
  .Home .sec01 .content .bottomData .right .circular h4 {
    font-size: 3vw;
    font-weight: bold;
    color: #3A60FF;
    font-family: Helvetica;
    line-height: 1.04vw;
    margin-bottom: 0.81vw;
  }
  .Home .sec01 .content .bottomData .right .circular span {
    display: inline-block;
    line-height: 1.8vw;
    font-size: 1.1vw;
  }
  .Home .sec03 .content .cardDiv .card div {
    padding: 1.32vw 0vw 4.58vw;
    height: 23vw;
  }
  .Home .sec03 .content .cardDiv .card div .bigFont {
    font-size: 1.7vw;
    line-height: 1.7vw;
    transform: scale(0.8);
    margin-bottom: 0;
    transform-origin: center top;
  }
  .Home .sec03 .content .cardDiv .card div .subFont {
    font-size: 1.4vw;
    transform: scale(0.8);
    line-height: 1.7vw;
    margin-bottom: 0;
    transform-origin: center top;
  }
  .Home .sec03 .content .cardDiv .card div .smallFont {
    font-size: 1.5vw;
    line-height: 1.7vw;
    transform: scale(0.7);
    transform-origin: center top;
  }
}

/* mobile */
@media(min-width: 320px) and (max-width: 767px) {
  .Home .ant-col-offset-5 {
    margin-left: 0 !important;
    overflow: hidden;
  }

  .Home .ant-col-15, .Home .ant-col-14, .Home .ant-col-12, .Home .ant-col-8 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .Home .sec01 {
    background: url('../../assets/images/bannerMobile.png') 42% 0% no-repeat,#00001d;
    position: relative;
    background-size: 150%;
  }

  .Home .sec01 .content {
    text-align: center;
  }

  .Home .sec01 .content h1 {
    padding-top: 75vw;
    font-size: 9.6vw;
    margin-bottom: 3.2vw;
    line-height: 10.93vw;
  }

  .Home .sec01 .content h2 {
    font-size: 9.6vw;
    margin-bottom: 5.06vw;
    line-height: 9.6vw;
  }

  .Home .sec01 .content span.colorSpan {
    width: 4vw;
    height: 1.33vw;
    display: block;
    margin: 0 auto 11.46vw;
  }

  .Home .sec01 .content a.Btn {
    width: 89.86vw;
    height: 12vw;
    margin: 0 auto 16.13vw;
    font-size: 4vw;
    line-height: 11vw;
    border-radius: 0.8vw;
    background: linear-gradient(-45deg, #BC2FBB, #3061CF);
  }

  .Home .sec01 .content .pc {
    display: none;
  }

  .Home .sec01 .content .mobile {
    display: block;
  }

  .Home .sec01 .content p.textP span {
    font-size: 4.8vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 2.66vw;
    display: block;
    margin-bottom: 6.26vw;
    text-align: center;
  }

  .Home .sec01 .content p.textP span:first-child {
    margin-right: 0;
    float: none;
  }
  
  .Home .sec01 .content .bottomData .left {
    float: left;
    /* margin-right: 22.29vw; */
    vertical-align: top;
  }

  .Home .sec01 .content .mobile .left img {
    margin: 0 4vw;
    width: 16vw;
  }

  .Home .sec01 .content .bottomData  {
    margin-top: 13.3vw;
  }

  .Home .sec01 .content .bottomData .right {
    vertical-align: top;
    float: none;
  }

  .Home .sec01 .content .bottomData p.textP {
    margin-bottom: 7.46vw;
  }

  .Home .sec01 .content .bottomData p.textP span {
    line-height: 2.66vw;
    display: inline-block;
    margin: 0;
  }

  .Home .sec01 .content .mobile .bottomData .right .circular {
    width: 28vw;
    height: 28vw;
    padding: 9.6vw 1.06vw 8.66vw;
    border: 0.1vw solid #3A60FF;
    box-shadow: 0 0px 0.52vw 0px #3A60FF;
    border-radius: 50%;
    text-align: center;
    margin-right: 2.93vw;
    display: inline-block;
    vertical-align: top;
    background: #202230;
    margin-bottom: 0;
  }

  .Home .sec01 .content .mobile .bottomData .right .circular h4 {
    font-size: 6.4vw;
    font-family: Arial;
    font-weight: bold;
    color: #3A60FF;
    line-height: 2.67vw;
  }

  .Home .sec01 .content .mobile .bottomData .right .circular span {
    font-size: 3.2vw;
    font-family: Arial;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 4vw;
    display: inline-block;
    margin-top: 1.75vw;
  }

  .Home .sec01 .content .mobile .bottomData .right .circular:nth-child(2) {
    border: 0.1vw solid #8B52F6;
    box-shadow: 0 0 0.52vw 0 #8B52F6;
  }

  .Home .sec01 .content .mobile .bottomData .right .circular:nth-child(2) h4 {
    color: #8B52F6;
  }

  .Home .sec01 .content .mobile .bottomData .right .circular:last-child {
    margin-right: 0;
    border: 0.1vw solid #D82FF6;
    box-shadow: 0 0 0.52vw 0 #D82FF6;
  }

  .Home .sec01 .content .mobile .bottomData .right .circular:last-child h4 {
    color: #D82FF6;
  }

  /* sec02 */
  .Home .sec02 {
    background-size: 150% 100%;
    background-position: center center;
    margin-top: -5.2vw;
    text-align: left;
    padding: 0 9.46vw 11.46vw;
  }

  .Home .sec02 .bgDiv {
    background: transparent;
    background-repeat: no-repeat;
    background-size: 40%, 5%;
    background-position: 0% 140%, 94% 100%;
  }

  .Home .sec02 .content .title {
    position: relative;
    padding-top: 28vw;
    font-size: 7vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 8vw;
    display: block;
    margin: 0 auto 3.33vw;
    text-align: center;
  }

  .Home .sec02 .content .titleLine {
    width: 6.66vw;
    height: 0.4vw;
    background: #D82FF6;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 6.53vw;
  }

  .Home .sec02 .content .textDiv p {
    font-size: 3.73vw;
    font-family: Helvetica;
    font-weight: 525;
    color: #FFFFFF;
    line-height: 5.6vw;
    margin-bottom: 5vw;
  }

  .Home .sec02 .content .textDiv p:last-child {
    margin-bottom: 0;
  }

  /* sec03 */
  .Home .sec03 {
    padding: 0 9.33vw;
    background-size: 10%;
    background-position: 5% 100%;
  }

  .Home .sec03 .content .title {
    position: relative;
    padding-top: 12.53vw;
    font-size: 7vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 8vw;
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .Home .sec03 .content .titleLine {
    width: 6.66vw;
    height: 0.4vw;
    background: #D82FF6;
    display: block;
    margin: 3.2vw auto 2.93vw;
    text-align: center;
  }

  .Home .sec03 .content .textDiv p.pc {
    display: none;
  }

  .Home .sec03 .content .textDiv p.mobile {
    font-size: 4vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 6vw;
    text-align: center;
    margin-bottom: 8.53vw;
    display: block;
  }

  .Home .sec03 .content .cardDiv .card div {
    background: #202230;
    border-radius: 1.33vw;
    padding: 7.33vw 6.26vw 12.4vw;
    text-align: center;
    width: 81.28vw;
    height: 69.33vw;
    margin-bottom: 8.53vw;
  }

  .Home .sec03 .content .cardDiv .card div img {
    width: 15.26vw;
    height: 15.26vw;
    margin-bottom: 6.26vw;
  }

  .Home .sec03 .content .cardDiv .card div .bigFont {
    font-size: 5.26vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: auto;
    margin-bottom: 3.5vw;
  }
  
  .Home .sec03 .content .cardDiv .card div .subFont {
    font-size: 3.46vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 4.54vw;
    margin-bottom: 4.93vw;
  }
  
  .Home .sec03 .content .cardDiv .card div .smallFont {
    font-size: 3.2vw;
    font-family: Helvetica;
    font-weight: 525;
    color: #FFFFFF;
    line-height: 4vw;
  }

  /* sec4 */
  .Home .sec04{
    width: 100%;
    position: relative;
    background-size: 200% 100%;
    background-position: center center;
    padding-top: 32vw;
    padding-bottom: 18.26vw;
    margin-top: -18vw;
  }

  .Home .sec04 .content h2.title {
    position: relative;
    padding-top: 0vw;
    font-size: 7vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 8vw;
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .Home .sec04 .content .titleLine {
    width: 6.66vw;
    height: 0.4vw;
    background: #D82FF6;
    display: block;
    margin: 3.06vw auto 14.4vw;
    text-align: center;
  }

  .Home .sec04 .content .leftCard1 {
    text-align: center;
  }

  .Home .sec04 .content .leftCard1 img {
    width: 37.46vw;
    margin-bottom: 7.46vw;
  }

  .Home .sec04 .content .rightCard1 div h2 {
    padding: 0 10.66vw;
    font-size: 4.8vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 4.8vw;
    margin-bottom: 5.33vw;
  }

  .Home .sec04 .content .rightCard1 div p {
    padding: 0 10.66vw;
    font-size: 3.46vw;
    font-family: Helvetica;
    font-weight: 525;
    color: #FFFFFF;
    line-height: 5.2vw;  
    margin-bottom: 5.33vw;
  }

  .Home .sec04 .content .rightCard1 div p:last-child {
    margin-bottom: 0;
  }

  .Home .sec04 .content .rightCard1 div h2 img {
    width: 5.06vw;
    height: 5.06vw;
    margin-right: 4vw;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1.06vw;
  }

  .Home .sec04 .content .rightCard1 div p.title {
    font-size: 5.8vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 6.4vw;
  }

  .Home .sec04 .content .mobileRever {
    display: flex;
    flex-direction: column-reverse;
  }

  .Home .sec04 .content .rightCard2 {
    margin-top: 19.6vw;
    text-align: center;
  }

  .Home .sec04 .content .rightCard2 img {
    width: 40.8vw;
  }

  .Home .sec04 .content .leftCard2 div h2 {
    margin-top: 9.6vw;
    padding: 0 10.66vw;
    font-size: 4.8vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 4.8vw;
    margin-bottom: 5.33vw;
  }

  .Home .sec04 .content .leftCard2 div p {
    padding: 0 10.66vw;
    font-size: 3.46vw;
    font-family: Helvetica;
    font-weight: 525;
    color: #FFFFFF;
    line-height: 5.2vw;  
    margin-bottom: 5.33vw;
  }

  .Home .sec04 .content .leftCard2 div p:last-child {
    margin-bottom: 0;
  }

  .Home .sec04 .content .leftCard2 div h2 img {
    width: 5.06vw;
    height: 5.06vw;
    margin-right: 4vw;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1.06vw;
  }

  .Home .sec04 .content .leftCard2 div p.title {
    font-size: 4.8vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 6.4vw;
    margin-bottom: 6.4vw;
  }

  .Home .sec04 .content .leftCard3 {
    margin-top: 17.06vw;
    text-align: center;
  }

  .Home .sec04 .content .leftCard3 img {
    width: 45.6vw;
  }

  .Home .sec04 .content .rightCard3 div h2 {
    margin-top: 9.6vw;
    padding: 0 10.66vw;
    font-size: 4.8vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 4.8vw;
    margin-bottom: 5.33vw;
  }

  .Home .sec04 .content .rightCard3 div p {
    padding: 0 10.66vw;
    font-size: 3.46vw;
    font-family: Helvetica;
    font-weight: 525;
    color: #FFFFFF;
    line-height: 5.2vw;  
    margin-bottom: 5.33vw;
  }

  .Home .sec04 .content .rightCard3 div p:last-child {
    margin-bottom: 0;
  }

  .Home .sec04 .content .rightCard3 div h2 img {
    width: 5.06vw;
    height: 5.06vw;
    margin-right: 4vw;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1.06vw;
  }

  .Home .sec04 .content .rightCard3 div p.title {
    font-size: 4.8vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 6.4vw;
    margin-bottom: 6.4vw;
  }

  /* sec05 */
  .Home .sec05.pc {
    display: none;
  }
  
  .Home .sec05.mobile {
    display: block;
    padding-top: 16.26vw;
    text-align: center;
  }
  
  .Home .sec05 .content .leftCard img {
    width: 53.06vw;
    margin-bottom: 7.06vw;
  }
  
  .Home .sec05 .content .leftCard div h2 {
    font-size: 5.6vw;
    font-family: Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 3.6vw;
    line-height: 5.6vw;
    margin-top: 0;
  }
  
  .Home .sec05 .content .leftCard div p {
    padding: 0 10.66vw;
    font-size: 3.73vw;
    font-family: Helvetica;
    font-weight: 525;
    color: #FFFFFF;
    line-height: 5.6vw;  
    margin-bottom: 8.33vw;
    text-align: left;
  }
  
  .Home .sec05 .content .titleLine {
    width: 6.66vw;
    height: 0.4vw;
    background: #D82FF6;
    display: block;
    margin: 3.06vw auto 8.26vw;
    text-align: center;
  }
  
  /* sec06 */
  .Home .sec06{
    width: 100%;
    position: relative;
    background-size: 200% 100%;
    background-position: center center;
    padding-top: 34vw;
    padding-bottom: 5.73vw;
    margin-top: -9vw;
  }

  .Home .sec06 .ant-col-6 {
    max-width: 40% !important;
    flex: 0 0 40% !important;
  }

  .Home .sec06 .ant-col-6:nth-child(2) {
    margin-right: 0;
  }
  
  .Home .sec06 .ant-col-6:first-child, .Home .sec06 .ant-col-6:last-child {
    max-width: 10% !important;
    flex: 0 0 10% !important;
  }
  
  .Home .sec06 .content .left a, .Home .sec06 .content .right a {
    font-size: 3.2vw;
    font-family: Helvetica;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 8vw;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .Home .sec06 .footerBottom .footerLine {
    display: block;
    width: 81.33vw;
    height: 0.13vw;
    background: #4F4DD4;
    opacity: 0.22;
    margin: 8.66vw auto 4.93vw;
  }
  
  .Home .sec06 .footerBottom .copy {
    width: 100%;
    padding: 0 9.46vw;
    text-align: left;
  }
  
  .Home .sec06 .footerBottom .copy span {
    font-size: 3.2vw;
    font-family: Arial;
    font-weight: 400;
    line-height: 6vw;
    color: #FFFFFF;
    margin: 0;
    display: inline-block;
    width: 100%;
    opacity: 0.5;
  }
}