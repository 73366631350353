/* sec06 */
.swapSec06{
  width: 100%;
  position: relative;
  background: url('../../assets/images/sec6Bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 288px;
  margin-bottom: -80px;
}

.swapSec06 .content .left a, .swapSec06 .content .right a {
  font-size: 20px;
  font-family: Helvetica;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 40px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.swapSec06 .footerBottom .footerLine {
  display: block;
  width: 38.12vw;
  height: 0.05vw;
  background: #5049D4;
  opacity: 0.22;
  margin: 44px auto 20px;
}

.swapSec06 .footerBottom .copy {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

.swapSec06 .footerBottom .copy span {
  font-size: 13px;
  font-family: Arial;
  font-weight: 400;
  color: #FFFFFF;
  margin: 10px 18px 20px;
  display: inline-block;
  opacity: 0.5;
}

.swapSec06 .ant-col-6 {
  max-width: 34%;
  flex: 0 0 34%
}

.swapSec06 .ant-col-6:nth-child(2) {
  margin-right: 9%;
}

.swapSec06 .ant-col-6:first-child, .swapSec06 .ant-col-6:last-child {
  max-width: 18%;
  flex: 0 0 18%
}

.showInfo {
  border: 1px solid #2B2E40;
  display: inline-flex !important;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  justify-content: inherit;
}

.showInfo span {
  cursor: pointer;
}

.showInfo.show {
  background: #161522;
  margin-bottom: -24px;
  border: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.showDiv div:first-child{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.showDiv div:first-child div {
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}

.guide-link{
  color: white;
  margin-top: 20px;
}
.guide-link a{
  color: rgb(211, 211, 211);
}
.guide-link a:hover{
  color: rgb(182, 180, 180);
}

@media(min-width: 320px) and (max-width: 960px) {
  .swapSec06 .ant-col-15, .swapSec06 .ant-col-14, .swapSec06 .ant-col-12, .swapSec06 .ant-col-8 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .swapSec06 .ant-col-offset-5 {
    margin-left: 0 !important;
  }
  .swapSec06{
    width: 100%;
    position: relative;
    background-size: 300% 100%;
    background-position: center center;
    padding-bottom: 5.73vw;
    padding-top: 40vw;
    margin-bottom: -230px;
  }

  .swapSec06 .ant-col-6 {
    max-width: 40% !important;
    flex: 0 0 40% !important;
  }

  .swapSec06 .ant-row {
    display: inline-flex;
    width: 100%;
  }

  .swapSec06 .ant-col-6:nth-child(2) {
    margin-right: 0;
  }
  
  .swapSec06 .ant-col-6:first-child, .swapSec06 .ant-col-6:last-child {
    max-width: 10% !important;
    flex: 0 0 10% !important;
  }
  .swapSec06 .content .left a, .swapSec06 .content .right a {
    font-size: 3.2vw;
    font-family: Helvetica;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 8vw;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .swapSec06 .footerBottom .footerLine {
    display: block;
    width: 81.33vw;
    height: 0.13vw;
    background: #4F4DD4;
    opacity: 0.22;
    margin: 8.66vw auto 4.93vw;
  }
  
  .swapSec06 .footerBottom .copy {
    width: 100%;
    padding: 0 9.46vw;
    text-align: left;
    margin-bottom: 0;
  }
  
  .swapSec06 .footerBottom .copy span {
    font-size: 3.2vw;
    font-family: Arial;
    font-weight: 400;
    line-height: 6vw;
    color: #FFFFFF;
    margin: 0;
    display: inline-block;
    width: 100%;
    opacity: 0.5;
  }
}